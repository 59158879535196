/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-lg-chem-ci-gray {
  background-image: url(/ess/resource/pc/common/images/sprite.png);
  background-position: 0px 0px;
  width: 76px;
  height: 17px;
}
.icon-lg-chem-ci {
  background-image: url(/ess/resource/pc/common/images/sprite.png);
  background-position: 0px -27px;
  width: 76px;
  height: 17px;
}
